import { DeviceType, EventType } from '@wix/platform-editor-sdk';
import { install } from './components/webComponent/editor.controller';
import { IWebComponent } from './components/webComponent/elements/customElement';
import {
  deleteMetaSiteCacheData,
  getMetaSiteCacheData,
  isEligibleForUpgrade,
} from './utils/editor';
import {
  addComponentToPage,
  filterPage,
  filterWidgets,
  findPagesThatHaveNotBeenInstalledInThePast,
  saveSettingsToTPService,
  updateViewMode,
} from './utils/helpers';
import { EditorReadyFn } from '@wix/yoshi-flow-editor';

const TOKEN = 'TOKEN';

let gfppSetup: any = () => ({
  desktop: {
    controllersStageData: {
      webComponent: {
        default: {
          gfpp: {
            desktop: {
              mainAction1: {
                actionId: 'connect',
                label: 'Settings',
              },
              mainAction2: 'HIDE',
              iconButtons: {
                animation: 'HIDE',
              },
              helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
            },
          },
        },
      },
    },
    exports: {
      webComponent: {
        tagname: 'webComponent',
        widgetDisplayName: '',
        eventHandlers: {},
        synthetic: false,
        inherits: {},
      },
    },
  },
});
export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  editorOptions,
  flowApi,
) => {
  const { firstInstall } = editorOptions;
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );

  const metaSiteData = await getMetaSiteCacheData(editorSDK);

  if (metaSiteData) {
    await deleteMetaSiteCacheData(editorSDK);
  }

  const isUpgrade: boolean = await isEligibleForUpgrade(
    editorSDK,
    appDefinitionId,
  );
  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string }) => comp.type === 'WEB',
      )) ||
    {};
  const webComponents: IWebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  gfppSetup = () => {
    const { connectLabel } = webComponent.data || {};
    const hasDashboard = appData.components?.some(
      (comp: { type: string }) =>
        comp.type.toLocaleUpperCase() === 'DASHBOARD' ||
        comp.type.toLocaleUpperCase() === 'MULTIPLE_DASHBOARDS',
    );
    return {
      controllersStageData: {
        ...webComponents.reduce(
          (acc: any, widget: any) => {
            acc[widget?.componentId ?? 'webComponent'] = {
              default: {
                gfpp: {
                  desktop: {
                    mainAction1: {
                      actionId: 'connect',
                      label: connectLabel || 'Settings',
                    },
                    mainAction2: hasDashboard
                      ? {
                          actionId: 'dashboard',
                          label: 'Manage',
                        }
                      : 'HIDE',
                    iconButtons: {
                      animation: 'HIDE',
                      upgrade: isUpgrade ? 'DEFAULT' : 'HIDE',
                    },
                    helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
                  },
                },
              },
            };
            return acc;
          },
          {
            webComponent: {
              default: {
                gfpp: {
                  desktop: {
                    mainAction1: {
                      actionId: 'connect',
                      label: connectLabel || 'Settings',
                    },
                    mainAction2: 'HIDE',
                    iconButtons: {
                      animation: 'HIDE',
                      upgrade: isUpgrade ? 'DEFAULT' : 'HIDE',
                    },
                    helpId: '6e37b6d8-f730-4afc-a1d0-a4d0ccbb1bcb',
                  },
                },
              },
            },
          },
        ),
      },
      exports: {
        ...webComponents.reduce(
          (acc: any, widget: any) => {
            acc[widget?.componentId ?? 'webComponent'] = {
              tagname: webComponent?.componentId ?? 'webComponent',
              widgetDisplayName: '',
              eventHandlers: {},
              synthetic: false,
              inherits: {},
            };
            return acc;
          },
          {
            webComponent: {
              tagname: 'webComponent',
              widgetDisplayName: '',
              eventHandlers: {},
              synthetic: false,
              inherits: {},
            },
          },
        ),
      },
    };
  };

  await editorSDK.document.application.reloadManifest();
  if (firstInstall) {
    const webComponentsPages = filterPage(webComponents as IWebComponent[]);
    const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
      webComponentsPages as IWebComponent[],
      editorSDK,
    );
    if (pagesToAdd.length !== 0) {
      await install(editorSDK, appDefinitionId, metaSiteData, flowApi);
    } else {
      const widgets = filterWidgets(webComponents as IWebComponent[]);
      const pageRef = await editorSDK.pages.getCurrent(TOKEN);

      if (widgets.length === 1) {
        await addComponentToPage({
          flowApi,
          componentDefinition: widgets[0],
          editorSDK,
          instanceId: appData.instanceId,
          applicationId: appData.appDefinitionId,
          metaSiteData,
          pageRef,
        });
      } else {
        // open widgets modal
        editorSDK.editor.openModalPanel(TOKEN, {
          url: `https://www.wix.com/_serverless/dynamic-settings-server?wix-sdk-version=${
            editorSDK.info.getSdkVersion(TOKEN).scriptSrc
          }&openWidgetsModal=${true}&componentId=not_exists`,
          shouldHideHeader: true,
          width: 680,
          height: 423,
        });
      }
    }
  }

  // appData?.appDefinitionId !== 'aeecfaf9-9455-4457-b99d-265829eaf39f' &&
  //   editorSDK.addEventListener(EventType.componentDataChanged, async (evt) => {
  //     console.info('LOAD!!!!!!componentDataChanged');
  //     const { compRef } = evt.detail;
  //     const formFactor = await editorSDK.editor.info.getEditorMode(TOKEN);
  //     updateViewMode({ editorSDK, mode: formFactor, compRef });
  //   });

  editorSDK.addEventListener(EventType.switchedToMobileView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      },
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Mobile,
        compRef,
        appDefinitionId,
      });
    }
  });
  editorSDK.addEventListener(EventType.switchedToDesktopView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      },
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Desktop,
        compRef,
        appDefinitionId,
      });
    }
  });
  editorSDK.addEventListener(EventType.siteWasPublished, async () => {
    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToTPService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            componentId: component.componentId,
            instanceId: appData.instanceId,
            status: 'published',
          },
        });
    }
  });

  editorSDK.addEventListener(EventType.themeChanged, async () => {
    const [colors, fonts] = await Promise.all([
      editorSDK.document.theme.colors.getAll(TOKEN),
      editorSDK.document.theme.fonts.getMap(TOKEN),
    ]);

    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToTPService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
    }

    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      },
    );

    for await (const compRef of componentsByType) {
      updateViewMode({ editorSDK, compRef, appDefinitionId });
    }
  });

  editorSDK.addEventListener(
    EventType.anyComponentAddedToStage,
    async (data) => {
      const fullData: any = await editorSDK.components.data.get(TOKEN, {
        componentRef: data.detail.compRef,
      });
      // controllerType is the component Id
      const { applicationId, controllerType } = fullData;

      const appDataToUpdate: any = await editorSDK.document.tpa.app.getDataByAppDefId(
        applicationId,
        applicationId,
      );
      const component: any = appDataToUpdate.components?.find(
        (comp: any) => comp.componentId === controllerType,
      );

      if (component.data?.gfppSettings?.fetchInitialData) {
        const [colors, fonts] = await Promise.all([
          editorSDK.document.theme.colors.getAll(TOKEN),
          editorSDK.document.theme.fonts.getMap(TOKEN),
        ]);

        saveSettingsToTPService({
          appId: applicationId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
      }
    },
  );
};

export const getAppManifest = async () => {
  return gfppSetup();
};
