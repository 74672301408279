import { EditorSDK, ComponentRef, PageRef } from '@wix/platform-editor-sdk';
import { IWebComponent } from '../components/webComponent/elements/customElement';
import { HttpClient } from '@wix/http-client';
import { httpClient, initHttpClient } from './httpClient';

initHttpClient(new HttpClient({ baseURL: '' }));

const TOKEN = 'token';
const GENERAL_APPLICATION_ID = '22bef345-3c5b-4c18-b782-74d4085112ff';
const TPA_PAGE_ID = 'web_component_page';

const getEditorSdkSource = (editorSDK: EditorSDK) =>
  editorSDK.info.getSdkVersion(TOKEN).scriptSrc;

export const panelUrlBuilder = ({
  editorSDK,
  componentRef,
  panelName,
  dynamicSettings,
  compId,
  version,
}: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  panelName: string;
  dynamicSettings: boolean;
  compId?: string;
  version: string;
}) => {
  const inWatchMode = false; // process.env.NODE_ENV !== 'production';
  // During yoshi-flow-editor start we want have local rendered settings panel. For prod - we are using static html file.
  const baseUrl = inWatchMode
    ? `https://localhost:3000/settings/${panelName}`
    : dynamicSettings
    ? `https://www.wix.com/_serverless/dynamic-settings-server`
    : `./settings/${panelName}.html`;

  return `${baseUrl}?wix-sdk-version=${getEditorSdkSource(
    editorSDK,
  )}&componentId=${componentRef.id}&compId=${compId}&version=${version}`;
};

export async function openSettingsPanel(
  editorSDK: EditorSDK,
  panelName: string,
  { componentRef }: { componentRef: ComponentRef },
  appDefinitionId: string,
  title: string = 'Settings',
  height: number = 582,
  width: number = 402,
) {
  const appId = await editorSDK.document.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
    version?: string;
  } = await editorSDK.document.tpa.app.getDataByAppDefId(appId, appId);

  const controllerData = await editorSDK.document.controllers.getData(TOKEN, {
    controllerRef: componentRef,
  });

  const webComponent: IWebComponent =
    (appData &&
      appData.components &&
      appData.components.find(
        (comp: { type: string; componentId: string }) =>
          comp.type === 'WEB' &&
          comp.componentId === controllerData?.config?.componentId,
      )) ||
    {};

  // get application data to set the title, height, width
  editorSDK.editor.openComponentPanel(TOKEN, {
    title: webComponent?.data?.modalTitle ?? title,
    url: panelUrlBuilder({
      editorSDK,
      componentRef,
      panelName,
      dynamicSettings:
        appData?.appDefinitionId !== 'aeecfaf9-9455-4457-b99d-265829eaf39f',
      compId: webComponent.componentId,
      version: appData.version as string,
    }),
    height,
    width,
    componentRef,
  });
}

export async function addComponent({
  editorSDK,
  fullWidth = false,
  componentDefinition,
  pageRef,
}: {
  editorSDK: EditorSDK;
  componentDefinition: any;
  fullWidth?: boolean;
  pageRef: PageRef;
}) {
  const componentRef = await editorSDK.document.components.add(TOKEN, {
    componentDefinition,
    pageRef,
  });
  // if (fullWidth) {
  //   await editorSDK.document.components.setFullWidth(TOKEN, {
  //     componentRef,
  //     fullWidth: true,
  //   });
  // }
  return componentRef;
}

export async function findComponentsByType(
  editorSDK: EditorSDK,
  parentRef: ComponentRef,
  componentType: string,
) {
  const children = await editorSDK.document.components.getChildren(TOKEN, {
    componentRef: parentRef,
    recursive: true,
  });

  const result: ComponentRef[] = [];

  await Promise.all(
    children.map(async (child) => {
      const type = await editorSDK.components.getType(TOKEN, {
        componentRef: child,
      });

      if (type === componentType) {
        result.push(child);
      }
    }),
  );

  return result;
}

export async function isEligibleForUpgrade(
  editorSDK: EditorSDK | null,
  applicationId: string,
): Promise<boolean> {
  if (!editorSDK) {
    return false;
  }
  try {
    const generalApp = await editorSDK.document.tpa.app.getDataByAppDefId(
      GENERAL_APPLICATION_ID,
      GENERAL_APPLICATION_ID,
    );
    const generalInstance = generalApp.instance;

    const managedAppsData: any = await httpClient.get(
      'https://editor.wix.com/_api/marketplace-api/v1/managed-apps',
      { headers: { Authorization: generalInstance }, withCredentials: true },
    ); // TODO: support editor x and ADI

    const managedWebSolutions =
      managedAppsData?.data?.managedWebSolutions ?? [];

    return managedWebSolutions.some(
      (item: {
        eligibleForUpgrade: boolean;
        webSolutionBase: { id: string };
      }) =>
        item?.webSolutionBase?.id === applicationId && item.eligibleForUpgrade,
    );
  } catch (error) {
    // console.log('Error fetch managed apps');
    return false;
  }
}

export async function isComponentIsInStageWithController(
  editorSDK: EditorSDK,
  componentId: undefined | string,
): Promise<boolean> {
  if (!componentId) {
    return false;
  }
  const controllers = await editorSDK.controllers.listAllControllers(TOKEN, {
    includeTPAWidget: true,
  });
  const controllersData = await Promise.all(
    controllers.map((controller) =>
      editorSDK.controllers.getData(TOKEN, {
        controllerRef: controller.controllerRef,
      }),
    ),
  );
  const components =
    controllersData?.map((data) => data.config.componentId) ?? [];
  return components.includes(componentId);
}

export async function getMetaSiteCacheData(editorSDK: EditorSDK): Promise<any> {
  try {
    const metaSiteId: string = await editorSDK.document.info.getMetaSiteId(
      TOKEN,
    );
    const cacheData: any =
      (
        await httpClient.get(
          `https://editor.wix.com/_serverless/deep-link-editor-cache/data/${metaSiteId}`,
        )
      )?.data ?? {};
    return cacheData?.data?.data;
  } catch (e) {
    return undefined;
  }
}

export async function deleteMetaSiteCacheData(
  editorSDK: EditorSDK,
): Promise<void> {
  try {
    const metaSiteId: string = await editorSDK.document.info.getMetaSiteId(
      TOKEN,
    );
    const deleteResult: any = await httpClient.delete(
      `https://editor.wix.com/_serverless/deep-link-editor-cache/data/${metaSiteId}`,
    );
    if (deleteResult?.status === 200 && deleteResult?.data?.status === 204) {
      return;
    }
    // console.log('Got an error when try to delete metasite CacheData');
    return;
  } catch (e) {
    return;
  }
}
