import { EditorSDK } from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { WidgetEditor, WidgetEventHandlers } from '../../types/editor';
import { openSettingsPanel } from '../../utils/editor';
import {
  addComponentToPage,
  createNewPage,
  dashboardAction,
  filterPage,
  findPagesThatHaveNotBeenInstalledInThePast,
} from '../../utils/helpers';
import component from './.component.json';
import { IWebComponent } from './elements/customElement';

export const TOKEN = 'token';

// install will happen only first time and only for pages.
export async function install(
  editorSDK: EditorSDK,
  appDefinitionId: string,
  metaSiteData: any = {},
  flowApi: FlowAPI,
) {
  const applicationId = await editorSDK.info.getAppDefinitionId(TOKEN);
  const appData: {
    applicationId: number;
    appDefinitionId: string;
    instance: string;
    instanceId: string;
    isWixTPA: boolean;
    components?: [];
  } = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId,
  );
  // @ts-expect-error
  const webComponents: IWebComponent[] = appData.components.filter(
    (comp: { type: string }) => comp.type === 'WEB',
  );
  const webComponentsPages = filterPage(webComponents as IWebComponent[]);

  const pagesToAdd = await findPagesThatHaveNotBeenInstalledInThePast(
    webComponentsPages as IWebComponent[],
    editorSDK,
  );

  for (const componentDefinition of pagesToAdd) {
    const pageRef = await createNewPage({
      componentDefinition,
      editorSDK,
      appDefinitionId,
    });
    await addComponentToPage({
      flowApi,
      pageRef,
      componentDefinition,
      editorSDK,
      instanceId: appData.instanceId,
      applicationId,
      metaSiteData,
      fullWidth: true,
      isPage: true,
    });
  }

  pagesToAdd.length > 1 &&
    (await editorSDK.pages.navigateTo(TOKEN, {
      pageRef: { id: pagesToAdd[0].componentId as string },
    }));
}

const customElementEventsHandler: WidgetEventHandlers = {
  widgetGfppClicked: {
    connect: (payload, editorSDK) =>
      openSettingsPanel(editorSDK, component.id, payload, '123'),
    dashboard: async (payload, editorSDK) => {
      // const apiUrl = await editorSDK.appSettings.getApiUrl();
      const appId = await editorSDK.document.info.getAppDefinitionId(TOKEN);
      const appData: {
        applicationId: number;
        appDefinitionId: string;
        instance: string;
        instanceId: string;
        isWixTPA: boolean;
        components?: [];
        version?: string;
      } = await editorSDK.document.tpa.app.getDataByAppDefId(appId, appId);

      const compnent: {
        controllerType?: string;
      } = await editorSDK.components.data.get(TOKEN, {
        componentRef: payload.componentRef,
      });
      const isMultiDashboard = appData.components?.some(
        (comp: IWebComponent) => comp.componentId === compnent.controllerType,
      );
      dashboardAction({
        multiDashboardData:
          isMultiDashboard && (compnent.controllerType as string),
        editorSDK,
        appDefId: appData.appDefinitionId,
        instance: appData.instance,
      });
    },
  },
};

function createCustomElement(): WidgetEditor {
  return {
    type: component.id,
    getEventHandlers() {
      return customElementEventsHandler;
    },
  };
}

export default createCustomElement();
